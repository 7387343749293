<template>
  <div class="scene" ref="sceneDiv"></div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import * as THREE from "three";
import gsap from "gsap";

// 导入gui对象
// import gui from "@/three/gui";
import * as dat from "dat.gui";
//创建gui对象
// const gui = new dat.GUI();
// 导入场景
import scene from "@/three/scene";

import cameraModule from "@/three/camera";
// 导入控制器
import controlModule from "@/three/controls";
// 导入辅助坐标轴
// import axesHelper from "@/three/axesHelper";
// 导入渲染器
import rendererModule from "@/three/renderer";
// 初始化调整屏幕
import "@/three/init";
// 导入添加物体函数
import createMesh from "@/three/createMesh";
// import { updateMesh } from "@/three/createMesh";
// 导入每一帧的执行函数
import animate from "@/three/animate";

import eventHub from "@/utils/eventHub";
// import WaterBody from "@/three/mesh/CreateWater";

console.log(controlModule);
// 场景元素div
let sceneDiv = ref(null);
// 添加相机
scene.add(cameraModule.activeCamera);
// 添加辅助坐标轴
// scene.add(axesHelper);
// 创建物体
let city = createMesh();
animate(() => {
  controlModule.controls.update();
}, null);
// 创建事件的问题
const props = defineProps(["eventList"]);
let controlData = {
  faceColor: "#ffffff",
  bodyColor: "#ffffff",
  waterX: 0,
  waterY: 0,
  waterZ: 0,
  waterLong: 200,
  waterWidth: 100,
  waterLevel: 10,
};
let arrEventMesh = [];

onMounted(() => {
  sceneDiv.value.appendChild(rendererModule.renderer.domElement);
  window.addEventListener("click", (e) => {
    console.log(cameraModule.activeCamera.position, "相机位置");
    console.log(controlModule.controls.target, "控制器位置");
  });
  // animate(updateMesh, city);
  eventHub.on("pipeSpeed", (speed) => {
    city[`${speed.pipeName}`] = speed.speed;
  });
  eventHub.on("waterLevel", (level) => {
    scene.traverse((child) => {
      if (child.name === level.name) {
        child.position.y = level.level;
      }
    });
  });
  eventHub.on("mineWaterLevel", (level) => {
    scene.traverse((child) => {
      if (child.name === level.name) {
        child.scale.y = level.level;
        child.position.y = level.level - 3.5;
      }
    });
  });
  let params = {
    value: 1,
  };
  let params2 = {
    value: 0,
  };
  eventHub.on("zdzPumpSwitch", (onOff) => {
    scene.traverse((child) => {
      if (child.name === "0beng01" || child.name === "0beng02") {
        child.updateMorphTargets();
        gsap.to(params, {
          value: onOff ? 0 : 1,
          duration: 2,
          onUpdate: () => {
            child.morphTargetInfluences[0] = params.value;
          },
        });
      }
    });
  });
  eventHub.on("ltzPumpSwitch", (onOff) => {
    scene.traverse((child) => {
      if (child.name === "0beng03") {
        if (onOff) {
          const animations = child.geometry.morphAttributes.position;
          child.geometry.morphAttributes.position = [];
          child.geometry.morphAttributes.position.push(...animations);
        }
        child.updateMorphTargets();
        gsap.to(params2, {
          value: onOff ? 1 : 0,
          duration: 2,
          onUpdate: () => {
            child.morphTargetInfluences[0] = params2.value;
          },
        });
      }
    });
  });
  eventHub.on("initZdz", () => {
    cameraModule.activeCamera.position.set(125.724, 60.316, 175.9354);
    controlModule.controls.target.set(29.54, -25.8648, 11.5727);
    let onOff = true;
    // 水体
    scene.traverse((child) => {
      if (child.name === "city1") {
        child.visible = true;
      }
      if (child.name === "city2") {
        child.visible = false;
      }
    });
  });
  eventHub.on("initGlz", () => {
    cameraModule.activeCamera.position.set(21.965, 51.52, 57.9622);
    controlModule.controls.target.set(-35.932, -2.015, -54.88);
    let onOff = false;
    // 水体
    scene.traverse((child) => {
      if (child.name === "city2") {
        child.visible = true;
      }
      if (child.name === "city1") {
        child.visible = false;
      }
      if (child.name === "0beng01" || child.name === "0beng02") {
        child.updateMorphTargets();
        gsap.to(params, {
          value: onOff ? 0 : 1,
          duration: 2,
          onUpdate: () => {
            child.morphTargetInfluences[0] = params.value;
          },
        });
      }
      if (child.name === "0beng03") {
        if (onOff) {
          const animations = child.geometry.morphAttributes.position;
          child.geometry.morphAttributes.position = [];
          child.geometry.morphAttributes.position.push(...animations);
        }
        child.updateMorphTargets();
        gsap.to(params2, {
          value: onOff ? 1 : 0,
          duration: 2,
          onUpdate: () => {
            child.morphTargetInfluences[0] = params2.value;
          },
        });
      }
    });
  });
});
</script>

<style>
.scene {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
}
.dg {
  position: absolute !important;
  z-index: 20000 !important;
}
</style>
