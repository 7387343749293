import * as THREE from "three";

export default class MineWaterBody {
  constructor(
    radius = 5,
    waterlevel = 10,
    x = 0,
    y = 0,
    z = 0,
    name = "",
    box = false
  ) {
    let bodyGeo;
    if (box) {
      bodyGeo = new THREE.BoxGeometry(19, 15, 28);
    } else {
      bodyGeo = new THREE.CylinderGeometry(radius, radius, waterlevel, 32);
    }
    const bodyMat = new THREE.MeshBasicMaterial({
      color: 0x1c6f83,
      depthTest: false,
      transparent: true,
      opacity: 0.6,
    });
    this.body = new THREE.Mesh(bodyGeo, bodyMat);
    // this.body.rotation.x = Math.PI * -0.5;
    this.mesh = new THREE.Group();
    this.mesh.add(this.body);
    this.mesh.name = name;
    this.mesh.position.set(x, y, z);
  }
}
