import axios from "axios";

const request = axios.create({
  baseURL: "https://api.themoviedb.org/3",
  timeout: 5000,
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // config.params = {
    //   ...config.params,
    //   api_key: "b7cd3371b788af77010b4db0f8ea060a",
    // };
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

//添加相应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
export default request;
