<template>
  <div id="bigScreen">
    <div v-show="uiVisible" class="header" id="header">
      <div class="header-bg">
        <div class="header-btn-right" @click="handleGoBackStation">
          {{ backToStation }}
        </div>
        <div class="header-btn-right" @click="handleGoHomeView">返回主页</div>
        <span> {{ mode }} </span>
      </div>
    </div>
    <div class="header-btn-fixed2" id="headerBtn" @click="handleUISwitch">
      UI开关
    </div>
    <div
      v-show="uiVisible"
      class="header-btn-fixed"
      id="headerBtn2"
      @click="handleAllPumpSwitch"
    >
      水泵开关
    </div>
    <div
      v-if="mode === '正达站' && uiVisible"
      class="main-container"
      id="main-container"
    >
      <div class="left">
        <div class="cityEvent">
          <h3>
            <img class="icon" src="../assets/bg/zhandian.png" alt="" />
            <span> 正达站 </span>
          </h3>
          <div class="pump-status">
            <div class="title">水泵开关</div>
            <div class="switch">
              <el-switch
                v-model="zdzPumpSwitch"
                inline-prompt
                active-text="on"
                inactive-text="off"
                @change="handleZdzPumpSwitch"
                style="
                  --el-switch-on-color: #0e488d;
                  --el-switch-off-color: #666666;
                "
                size="large"
              />
            </div>
          </div>
          <div class="water-speed">
            <div class="water-title">水流速度</div>
            <div class="water-speed-item">
              <span class="speed-title">进水管道</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                :show-tooltip="false"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="zdzPipeInSpeed"
                :min="0"
                :max="5"
                :step="0.01"
                @input="handlePipeInSpeed"
              />
              <div class="slider-data">{{ zdzPipeInSpeed }}</div>
            </div>
            <div class="water-speed-item">
              <span class="speed-title">出水管道</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                :min="0"
                :max="5"
                :show-tooltip="false"
                :step="0.01"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="zdzPipeOutSpeed"
                @input="handlePipeOutSpeed"
              />
              <div class="slider-data">{{ zdzPipeOutSpeed }}</div>
            </div>
          </div>
          <div class="water-speed">
            <div class="water-title">水位高度</div>
            <div class="water-speed-item">
              <span class="speed-title">当前水位</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                :min="0"
                :max="5"
                :show-tooltip="false"
                :step="0.01"
                style="--el-slider-main-bg-color: #09c0f0"
                @input="handleWaterLevel"
                v-model="zdzWaterLevel"
              />
              <div class="slider-data">{{ zdzWaterLevel }}</div>
            </div>
            <div class="water-speed-item">
              <span class="speed-title">阴井水位</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                :show-tooltip="false"
                :min="0"
                :step="0.01"
                :max="5"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="zdzMineWaterLevel"
                @input="handleMineWaterLevel"
              />
              <div class="slider-data">{{ zdzMineWaterLevel }}</div>
            </div>
          </div>
          <div class="footerBorder"></div>
        </div>
      </div>
      <div class="right">
        <div class="cityEvent">
          <h3>
            <img class="icon" src="../assets/bg/zhandian.png" alt="" />
            <span> 龙潭站 </span>
          </h3>
          <div class="pump-status">
            <div class="title">水泵开关</div>
            <div class="switch">
              <el-switch
                v-model="ltzPumpSwitch"
                inline-prompt
                @change="handleLtzPumpSwitch"
                active-text="on"
                inactive-text="off"
                style="
                  --el-switch-on-color: #0e488d;
                  --el-switch-off-color: #666666;
                "
                size="large"
              />
            </div>
          </div>
          <div class="water-speed">
            <div class="water-title">水流速度</div>
            <div class="water-speed-item">
              <span class="speed-title">进水管道1</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="ltzPipeIn1Speed"
                :min="0"
                :show-tooltip="false"
                :max="5"
                :step="0.01"
                @input="handlePipeInSpeed2"
              />
              <div class="slider-data">{{ ltzPipeIn1Speed }}</div>
            </div>
            <div class="water-speed-item">
              <span class="speed-title">进水管道2</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="ltzPipeIn2Speed"
                :show-tooltip="false"
                :min="0"
                :max="5"
                :step="0.01"
                @input="handlePipeInSpeed3"
              />
              <div class="slider-data">{{ ltzPipeIn2Speed }}</div>
            </div>
            <div class="water-speed-item">
              <span class="speed-title">出水管道</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="ltzPipeOutSpeed"
                :min="0"
                :show-tooltip="false"
                :max="5"
                :step="0.01"
                @input="handlePipeOutSpeed2"
              />
              <div class="slider-data">{{ ltzPipeOutSpeed }}</div>
            </div>
          </div>
          <div class="water-speed">
            <div class="water-title">水位高度</div>
            <div class="water-speed-item">
              <span class="speed-title">当前水位</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="ltzWaterLevel"
                :min="0"
                :show-tooltip="false"
                :step="0.01"
                :max="5"
                @input="handleWaterLevel2"
              />
              <div class="slider-data">{{ ltzWaterLevel }}</div>
            </div>
          </div>
          <div class="footerBorder"></div>
        </div>
      </div>
    </div>
    <div v-show="uiVisible" v-else class="main-container" id="main-container">
      <div class="left">
        <div class="cityEvent">
          <h3>
            <img class="icon" src="../assets/bg/zhandian.png" alt="" />
            <span> 干览站 </span>
          </h3>
          <div class="pump-status">
            <div class="title">水泵开关</div>
            <div class="switch">
              <el-switch
                v-model="glzPumpSwitch"
                inline-prompt
                active-text="on"
                @change="handleGlzPumpSwitch"
                inactive-text="off"
                style="
                  --el-switch-on-color: #0e488d;
                  --el-switch-off-color: #666666;
                "
                size="large"
              />
            </div>
          </div>
          <div class="water-speed">
            <div class="water-title">水流速度</div>
            <div class="water-speed-item">
              <span class="speed-title">进水管道</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                :min="0"
                :max="5"
                :show-tooltip="false"
                :step="0.01"
                @input="handlePipeInSpeed4"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="glzPipeInSpeed"
              />
              <div class="slider-data">{{ glzPipeInSpeed }}</div>
            </div>
            <div class="water-speed-item">
              <span class="speed-title">出水管道</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                :min="0"
                :show-tooltip="false"
                :max="5"
                :step="0.01"
                @input="handlePipeOutSpeed3"
                style="--el-slider-main-bg-color: #09c0f0"
                v-model="glzPipeOutSpeed"
              />
              <div class="slider-data">{{ glzPipeOutSpeed }}</div>
            </div>
          </div>
          <div class="water-speed">
            <div class="water-title">水位高度</div>
            <div class="water-speed-item">
              <span class="speed-title">当前水位</span>
              <el-slider
                class="speed-slider"
                input-size="small"
                :show-tooltip="false"
                :min="0"
                :step="0.01"
                :max="5"
                v-model="glzWaterLevel"
                @input="handleWaterLevel3"
              />
              <div class="slider-data">{{ glzWaterLevel }}</div>
            </div>
          </div>
          <div class="footerBorder"></div>
        </div>
      </div>
    </div>
    <div v-show="uiVisible" class="footer"></div>
    <div v-if="homeVisible" class="loading-model">
      <div v-if="loaded < 3" id="container">
        <!-- 进度条 -->
        <div id="per"></div>
      </div>
      <div v-else class="modules">
        <div class="module-item" @click="chooseMode('正达站', '干览站')">
          正达站
        </div>
        <div class="module-item" @click="chooseMode('干览站', '正达站')">
          干览站
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import eventHub from "@/utils/eventHub";
import gsap from "gsap";
import { watch } from "vue";
// 正达站水泵开关
const zdzPumpSwitch = ref(false);
// 龙潭站水泵开关
const ltzPumpSwitch = ref(false);
// 正达站进水管道速度
const zdzPipeInSpeed = ref(1);
// 正达站出水管道速度
const zdzPipeOutSpeed = ref(1);
// 正达站水位
const zdzWaterLevel = ref(5);
// 正达站阴井水位
const zdzMineWaterLevel = ref(5);
// 龙潭站进水管道1速度
const ltzPipeIn1Speed = ref(1);
// 龙潭站进水管道2速度
const ltzPipeIn2Speed = ref(1);
// 龙潭站出水管道速度
const ltzPipeOutSpeed = ref(1);
// 龙潭站水位
const ltzWaterLevel = ref(5);
// 干览站水泵开关
const glzPumpSwitch = ref(true);
// 干览站进水管道速度
const glzPipeInSpeed = ref(1);
// 干览站出水管道速度
const glzPipeOutSpeed = ref(1);
// 干览站水位
const glzWaterLevel = ref(5);
// 模型加载中标识符
const loading = ref(true);
// 主页是否可见标识符
const homeVisible = ref(true);
// 加载了几个模型
const loaded = ref(0);
// 模型加载进度
const modelProgress = ref(0);
// 当前站点
const mode = ref("");
// 返回站点
const backToStation = ref("");
// UI开关
const uiVisible = ref(true);
const exsist = ref(true);
// 水泵开关
const pumpSwitch = ref(true);
// 将滑块0-5水位高度数据格式化为3D世界真实高度
const formatWaterLevel = (value, format) => {
  const [minFormat, maxFormat] = format;
  const minRange = 0;
  const maxRange = 5;

  // 将value从 [0, 5] 区间映射到 [minFormat, maxFormat] 区间
  const mappedValue =
    ((value - minRange) * (maxFormat - minFormat)) / (maxRange - minRange) +
    minFormat;

  return mappedValue;
};
// 初始化正达站
const initZdz = () => {
  zdzPumpSwitch.value = true;
  zdzPipeInSpeed.value = 1;
  zdzPipeOutSpeed.value = 1;
  zdzWaterLevel.value = 5;
  zdzMineWaterLevel.value = 5;
  ltzPumpSwitch.value = true;
  ltzPipeIn1Speed.value = 1;
  ltzPipeIn2Speed.value = 1;
  ltzPipeOutSpeed.value = 1;
  ltzWaterLevel.value = 5;
  eventHub.emit("initZdz");
  eventHub.emit("waterLevel", {
    name: "waterBody1",
    level: formatWaterLevel(zdzWaterLevel.value, [-2.92, -1]),
  });
  eventHub.emit("mineWaterLevel", {
    name: "waterBody3",
    level: formatWaterLevel(zdzMineWaterLevel.value, [0.01, 1]),
  });
  eventHub.emit("waterLevel", {
    name: "waterBody2",
    level: formatWaterLevel(ltzWaterLevel.value, [-3.16, -1]),
  });
};
// 初始化干览站
const initGlz = () => {
  glzPumpSwitch.value = true;
  glzPipeInSpeed.value = 1;
  glzPipeOutSpeed.value = 1;
  glzWaterLevel.value = 5;
  eventHub.emit("initGlz");
  eventHub.emit("waterLevel", {
    name: "waterBody6",
    level: formatWaterLevel(glzWaterLevel.value, [-2.28, -1]),
  });
};
//返回首页
const handleGoHomeView = () => {
  homeVisible.value = true;
  eventHub.emit("zdzPumpSwitch", false);
  eventHub.emit("ltzPumpSwitch", false);
};
// 返回站点
const handleGoBackStation = () => {
  [mode.value, backToStation.value] = [backToStation.value, mode.value];
  if (mode.value === "正达站") {
    initZdz();
    handleLtzPumpSwitch(true);
    handleZdzPumpSwitch(true);
  } else {
    initGlz();
    handleGlzPumpSwitch(true);
  }
};
// 进水管道
const handlePipeInSpeed = (speed) => {
  // console.log("进水管道速度", speed);
  eventHub.emit("pipeSpeed", {
    pipeName: "Pipe4",
    speed: speed,
  });
};
const handlePipeInSpeed2 = (speed) => {
  // console.log("进水管道速度", speed);
  eventHub.emit("pipeSpeed", {
    pipeName: "Pipe5",
    speed: speed,
  });
};
const handlePipeInSpeed3 = (speed) => {
  // console.log("进水管道速度", speed);
  eventHub.emit("pipeSpeed", {
    pipeName: "Pipe7",
    speed: speed,
  });
};
const handlePipeInSpeed4 = (speed) => {
  // console.log("进水管道速度", speed);
  eventHub.emit("pipeSpeed", {
    pipeName: "Pipe10",
    speed: speed,
  });
};
// 出水管道
const handlePipeOutSpeed = (speed) => {
  for (let i = 1; i < 4; i++) {
    // const element = array[i];
    eventHub.emit("pipeSpeed", {
      pipeName: `Pipe${i}`,
      speed: speed,
    });
  }
};
const handlePipeOutSpeed2 = (speed) => {
  // const element = array[i];
  eventHub.emit("pipeSpeed", {
    pipeName: "Pipe6",
    speed: speed,
  });
  eventHub.emit("pipeSpeed", {
    pipeName: "Pipe8",
    speed: speed,
  });
};
const handlePipeOutSpeed3 = (speed) => {
  eventHub.emit("pipeSpeed", {
    pipeName: "Pipe9",
    speed: speed,
  });
};
// 水位控制
const handleWaterLevel = (level) => {
  eventHub.emit("waterLevel", {
    name: "waterBody1",
    level: formatWaterLevel(level, [-2.92, -1]),
  });
};
const handleMineWaterLevel = (level) => {
  eventHub.emit("mineWaterLevel", {
    name: "waterBody3",
    level: formatWaterLevel(level, [0.01, 1]),
  });
};
const handleWaterLevel2 = (level) => {
  eventHub.emit("waterLevel", {
    name: "waterBody2",
    level: formatWaterLevel(level, [-3.16, -1]),
  });
};
const handleWaterLevel3 = (level) => {
  eventHub.emit("waterLevel", {
    name: "waterBody6",
    level: formatWaterLevel(level, [-2.28, -1]),
  });
};
// 水泵开关
const handleZdzPumpSwitch = (onOff) => {
  console.log(onOff, "rertrtr");
  for (let i = 1; i < 5; i++) {
    eventHub.emit("pipeSpeed", {
      pipeName: `Pipe${i}`,
      speed: onOff ? 1 : 0,
    });
  }
  zdzPipeInSpeed.value = onOff ? 1 : 0;
  zdzPipeOutSpeed.value = onOff ? 1 : 0;
  eventHub.emit("zdzPumpSwitch", onOff);
};
const handleLtzPumpSwitch = (onOff) => {
  for (let i = 5; i < 9; i++) {
    eventHub.emit("pipeSpeed", {
      pipeName: `Pipe${i}`,
      speed: onOff ? 1 : 0,
    });
  }
  ltzPipeIn1Speed.value = onOff ? 1 : 0;
  ltzPipeIn2Speed.value = onOff ? 1 : 0;
  ltzPipeOutSpeed.value = onOff ? 1 : 0;
  eventHub.emit("ltzPumpSwitch", onOff);
};
const handleGlzPumpSwitch = (onOff) => {
  for (let i = 9; i < 11; i++) {
    eventHub.emit("pipeSpeed", {
      pipeName: `Pipe${i}`,
      speed: onOff ? 1 : 0,
    });
  }
  glzPipeInSpeed.value = onOff ? 1 : 0;
  glzPipeOutSpeed.value = onOff ? 1 : 0;
};
// 选择站点
const chooseMode = (data, backTo) => {
  mode.value = data;
  backToStation.value = backTo;
  if (mode.value === "正达站") {
    initZdz();
    handleZdzPumpSwitch(true);
    handleLtzPumpSwitch(true);
  } else {
    initGlz();
  }
  homeVisible.value = false;
  const bigScreen = document.getElementById("bigScreen"); // 获取进度条元素
  const header = document.getElementById("header"); // 获取进度条元素
  const headerBtn = document.getElementById("headerBtn"); // 获取进度条元素
  headerBtn.style.zIndex = "100";
  headerBtn.style.pointerEvents = "auto";
  const headerBtn2 = document.getElementById("headerBtn2"); // 获取进度条元素
  headerBtn2.style.zIndex = "100";
  headerBtn2.style.pointerEvents = "auto";
  bigScreen.style.pointerEvents = "none";
  header.style.position = "relative";
  header.style.zIndex = "100";
  header.style.pointerEvents = "auto";
};
onMounted(() => {
  const percentDiv = document.getElementById("per"); // 获取进度条元素
  gsap.to(modelProgress, {
    value: 90,
    duration: 1,
    ease: "none",
    onUpdate: () => {
      // console.log("加载进度", data);
      percentDiv.style.width = (modelProgress.value / 100) * 400 + "px"; //进度条元素长度
      percentDiv.style.textIndent =
        (modelProgress.value / 100) * 400 + 5 + "px"; //缩进元素中的首行文本
      percentDiv.innerHTML =
        Math.floor((modelProgress.value / 100) * 100) + "%"; //进度百分比
    },
    onComplete: () => {
      loaded.value += 1;
    },
  });
  eventHub.on("loaded", () => {
    console.log("loaded被触发了");
    loaded.value += 1;
  });
});
watch(
  () => loaded.value,
  (newVal) => {
    if (newVal === 3) {
      document.getElementById("container").style.display = "none";
      return;
    }
  },
  {
    immediate: false,
  }
);
const handleUISwitch = () => {
  uiVisible.value = !uiVisible.value;
};
const handleAllPumpSwitch = () => {
  pumpSwitch.value = !pumpSwitch.value;
  if (mode.value === "正达站") {
    handleLtzPumpSwitch(pumpSwitch.value);
    handleZdzPumpSwitch(pumpSwitch.value);
    zdzPumpSwitch.value = pumpSwitch.value;
    ltzPumpSwitch.value = pumpSwitch.value;
  } else {
    handleGlzPumpSwitch(pumpSwitch.value);
    glzPumpSwitch.value = pumpSwitch.value;
  }
};
</script>

<style>
#bigScreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;

  left: 0;
  top: 0;
  /* pointer-events: none; */
  display: flex;
  flex-direction: column;
}

.header {
  /* width: 1920px;
        height: 100px; */
  /* width: 19.2rem;
  height: 2rem; */
  background: linear-gradient(to top, rgba(4, 54, 106, 1), rgba(1, 23, 38, 1));
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  /* text-align: center; */
  color: rgb(226, 226, 255);
  /* font-size: 0.5rem; */
  /* padding-bottom: 0.2rem; */
  /* position: relative;
  z-index: 100; */
}
.header-bg {
  width: 100%;
  /* height: 100%; */
  height: 0.7rem;
  background-image: url(@/assets/bg/titleA.png);
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 20px;
}
.header-bg > span {
  font-size: 0.3rem;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  font-family: serif;
}

.main-container {
  flex: 1;
  width: 19.2rem;
  display: flex;
  justify-content: space-between;
}

.left {
  width: 4rem;
  /* background-color: #08163e; */
  /* background-image: url(@/assets/bg/line_img.png); */
  /* background-repeat: no-repeat; */
  /* background-size: contain; */
  /* background-position: right center; */
  /* border: 1px solid #33fefe; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0.4rem 0;
}

.right {
  width: 4rem;
  /* background-color: rgb(255,255,255,0.5); */
  /* background-image: url(@/assets/bg/line_img.png); */
  /* background-repeat: no-repeat; */
  /* background-size: contain; */
  /* background-position: left center; */
  /* background-color: #08163e; */
  /* border: 1px solid #33fefe; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.4rem 0;
  height: 100%;
}

.cityEvent {
  position: relative;
  width: 3.5rem;
  height: 7.2rem;
  margin-bottom: 0.5rem;
  background-image: url(@/assets/bg/bg_img03.png);
  /* background: linear-gradient(to right, rgba(8, 22, 62, 1), rgba(0, 0, 0, 1)); */
  /* background-image: url(@/assets/bg/lightBg.png); */
  background-repeat: repeat;
  pointer-events: auto;
}

.cityEvent::before {
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
  border-top: 4px solid rgb(34, 133, 247);
  border-left: 4px solid rgb(34, 133, 247);
  content: "";
  display: block;
}

.cityEvent::after {
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  right: 0;
  top: 0;
  border-top: 4px solid rgb(34, 133, 247);
  border-right: 4px solid rgb(34, 133, 247);
  content: "";
  display: block;
}
.footerBorder {
  position: absolute;
  bottom: 0;
  bottom: 0;
  width: 3.5rem;
  height: 0.4rem;
}
.footerBorder::before {
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
  border-bottom: 4px solid rgb(34, 133, 247);
  border-left: 4px solid rgb(34, 133, 247);
  content: "";
  display: block;
}

.footerBorder::after {
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom: 4px solid rgb(34, 133, 247);
  border-right: 4px solid rgb(34, 133, 247);
  content: "";
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

h1 {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0.3rem;
  justify-content: space-between;
  font-size: 0.3rem;
}
h3 {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.3rem 0.3rem;
  text-shadow: 0 0 5px #000;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.3rem;
  font-size: 0.4rem;
  font-family: serif;
  /* background-color: #0e488d; */
}

h1 > div {
  display: flex;
  align-items: center;
}
h1 span.time {
  font-size: 0.2rem;
  font-weight: normal;
}

.cityEvent li > p {
  color: #eee;
  padding: 0rem 0.3rem 0.3rem;
}
.list h1 {
  padding: 0.1rem 0.3rem;
}
.cityEvent.list ul {
  pointer-events: auto;
  cursor: pointer;
}

.cityEvent li.active h1 {
  color: red;
}
.cityEvent li.active p {
  color: red;
}
.loading-model {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0b1b2f;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 20000;
  pointer-events: auto;
  /* flex-direction: row; */
}
.modules {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.module-item {
  width: 600px;
  height: 300px;
  /* background-color: #fff; */
  background-image: url(@/assets/bg/home-btn-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  line-height: 300px;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
}
.demonstration {
  font-size: 0.2rem;
}
.pump-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  text-shadow: 0 0 5px #00ffff, 0 0 5px #00ffff;
  font-family: serif;
}
.pump-status > .title {
  font-size: 0.25rem;
  width: 60%;
}
.pump-status > .switch {
  scale: 1.4;
  padding-right: 0.8rem;
}
.water-speed {
  padding-top: 0.5rem;
}
.water-title {
  color: #fff;
  text-shadow: 0 0 5px #00ffff, 0 0 5px #00ffff;
  font-family: serif;
  font-size: 0.25rem;
}
.water-speed-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.speed-title {
  width: 60%;
  color: #fff;
  text-shadow: 0 0 5px #00ffff, 0 0 5px #00ffff;
  font-family: serif;
  font-size: 0.25rem;
}
.speed-slider {
  /* width: 10%; */
  scale: 0.5;
}
/* .footer { */
/* width: 19.2rem;
  height: 1rem; */
/* background-image: url(@/assets/bg/title2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  color: rgb(226, 226, 255);
  font-size: 0.4rem; */
/* } */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1rem;
  background-image: url(@/assets/bg/title2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}
.header-btn {
  width: 1.4rem;
  height: 0.4rem;
  background-image: url(@/assets/bg/button.png);
  background-size: cover;
  float: left;
  text-align: center;
  line-height: 0.4rem;
  margin: 0 0.1rem;
  font-family: serif;
  z-index: 100;
  cursor: pointer;
}
.header-btn-fixed {
  position: fixed;
  top: 1.5%;
  left: 8%;
  width: 1.4rem;
  height: 0.4rem;
  background-image: url(@/assets/bg/button.png);
  background-size: cover;
  text-align: center;
  line-height: 0.4rem;
  margin: 0 0.1rem;
  font-family: serif;
  z-index: 100;
  color: #fff;
  cursor: pointer;
}
.header-btn-fixed2 {
  position: fixed;
  top: 1.5%;
  left: 0%;
  width: 1.4rem;
  height: 0.4rem;
  background-image: url(@/assets/bg/button.png);
  background-size: cover;
  text-align: center;
  line-height: 0.4rem;
  margin: 0 0.1rem;
  font-family: serif;
  z-index: 100;
  color: #fff;
  cursor: pointer;
}
.header-btn-right {
  width: 1.4rem;
  height: 0.4rem;
  background-image: url(@/assets/bg/button.png);
  background-size: cover;
  float: right;
  text-align: center;
  margin: 0 0.1rem;
  line-height: 0.4rem;
  cursor: pointer;
}
#container {
  position: absolute;
  width: 400px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -8px;
  border-radius: 8px;
  border: 1px solid #009999;
  overflow: hidden;
}
#per {
  height: 100%;
  width: 0px;
  background: #00ffff;
  color: #00ffff;
  line-height: 15px;
}
.slider-data {
  color: #00ffff;
  padding-right: 10px;
  text-shadow: 0 0 5px #fff, 0 0 5px #fff;
  width: 20%;
}
</style>
