import * as THREE from "three";
import { Water } from "three/examples/jsm/objects/Water";
// import * as dat from "dat.gui";

export default class WaterBody {
  constructor(
    long = 50,
    width = 50,
    waterLevel = 50,
    x = 0,
    y = 0,
    z = 0,
    waterFace = true,
    waterBodyGeo = null,
    name = ""
  ) {
    const textureLoader = new THREE.TextureLoader();
    const params = {
      color: "#ffffff",
      scale: 1,
      flowX: 1,
      flowY: -1,
    };
    const bodyGeo =
      waterBodyGeo || new THREE.BoxGeometry(long, width, waterLevel);
    const bodyMat = new THREE.MeshStandardMaterial({
      color: 0x1c6f83,
      transparent: true,
      opacity: 0.5,
    });
    this.body = new THREE.Mesh(bodyGeo, bodyMat);
    this.body.rotation.x = Math.PI * -0.5;
    const waterFaceGeo = new THREE.PlaneGeometry(long, width, 10, 10);
    const flowMap = textureLoader.load(
      "./textures/water/Water_2_M_Normal.jpg",
      (texture) => {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      }
    );
    this.water = new Water(waterFaceGeo, {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: flowMap,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x163842,
      distortionScale: 3.7,
    });
    this.water.rotation.x = Math.PI * -0.5;
    this.water.position.y = waterLevel / 2 + 0.1;
    this.mesh = new THREE.Group();
    this.mesh.add(this.body);
    if (waterFace) {
      this.mesh.add(this.water);
    }
    this.mesh.name = name;
    this.mesh.position.set(x, y, z);
  }
}
