import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
// import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
// import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import * as THREE from "three";
import gsap from "gsap";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import cameraModule from "../camera";
// import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer";
import eventHub from "@/utils/eventHub";
// import { ShaderMaterial } from "three";
// import fragmentShader from "@/shader/fighter/fragmentShader.glsl";
// import vertexShader from "@/shader/fighter/vertexShader.glsl";
// import { usePipeAnimation } from "./PipeAnimation";
import animate from "@/three/animate";
import WaterBody from "@/three/mesh/CreateWater";
import MineWaterBody from "@/three/mesh/CreateMineWater";
// import * as dat from "dat.gui";
// const gui = new dat.GUI();

export default class City {
  constructor(scene) {
    const gltfLoader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("./draco/");
    dracoLoader.setDecoderConfig({ type: "js" });
    dracoLoader.preload();
    gltfLoader.setDRACOLoader(dracoLoader);
    this.scene = scene;
    this.floor1Group;
    this.floor2Group;
    this.wallGroup;
    this.floor2Tags = [];
    this.Pipe1 = 1;
    this.Pipe2 = 1;
    this.Pipe3 = 1;
    this.Pipe4 = 1;
    this.Pipe5 = 1;
    this.Pipe6 = 1;
    this.Pipe7 = 1;
    this.Pipe8 = 1;
    this.Pipe9 = 1;
    this.Pipe10 = 1;
    this.Pipe11 = 1;
    const pipeTexture1 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture1.wrapS = pipeTexture1.wrapT = THREE.RepeatWrapping;
    pipeTexture1.repeat.set(2, 2);
    pipeTexture1.needsUpdate = true;
    const pipeTexture2 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture2.wrapS = pipeTexture2.wrapT = THREE.RepeatWrapping;
    pipeTexture2.repeat.set(2, 2);
    pipeTexture2.needsUpdate = true;
    const pipeTexture3 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture3.wrapS = pipeTexture3.wrapT = THREE.RepeatWrapping;
    pipeTexture3.repeat.set(2, 2);
    pipeTexture3.needsUpdate = true;
    const pipeTexture4 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture4.wrapS = pipeTexture4.wrapT = THREE.RepeatWrapping;
    pipeTexture4.repeat.set(2, 2);
    pipeTexture4.needsUpdate = true;
    const pipeTexture5 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture5.wrapS = pipeTexture5.wrapT = THREE.RepeatWrapping;
    pipeTexture5.repeat.set(2, 2);
    pipeTexture5.needsUpdate = true;
    const pipeTexture6 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture6.wrapS = pipeTexture6.wrapT = THREE.RepeatWrapping;
    pipeTexture6.repeat.set(2, 2);
    pipeTexture6.needsUpdate = true;
    const pipeTexture7 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture7.wrapS = pipeTexture7.wrapT = THREE.RepeatWrapping;
    pipeTexture7.repeat.set(2, 2);
    pipeTexture7.needsUpdate = true;
    const pipeTexture8 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture8.wrapS = pipeTexture8.wrapT = THREE.RepeatWrapping;
    pipeTexture8.repeat.set(2, 2);
    pipeTexture8.needsUpdate = true;
    const pipeTexture9 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture9.wrapS = pipeTexture9.wrapT = THREE.RepeatWrapping;
    pipeTexture9.repeat.set(2, 2);
    pipeTexture9.needsUpdate = true;
    const pipeTexture10 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture10.wrapS = pipeTexture10.wrapT = THREE.RepeatWrapping;
    pipeTexture10.repeat.set(2, 2);
    pipeTexture10.needsUpdate = true;
    const pipeTexture11 = new THREE.TextureLoader().load("./textures/flow.png");
    pipeTexture11.wrapS = pipeTexture11.wrapT = THREE.RepeatWrapping;
    pipeTexture11.repeat.set(2, 2);
    pipeTexture11.needsUpdate = true;
    let params = {
      value: 1,
    };
    let depthMat = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.8,
      depthTest: false,
      side: THREE.DoubleSide,
    });
    let pipeMat1 = new THREE.MeshBasicMaterial({
      map: pipeTexture1,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat2 = new THREE.MeshBasicMaterial({
      map: pipeTexture2,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat3 = new THREE.MeshBasicMaterial({
      map: pipeTexture3,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat4 = new THREE.MeshBasicMaterial({
      map: pipeTexture4,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat5 = new THREE.MeshBasicMaterial({
      map: pipeTexture5,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat6 = new THREE.MeshBasicMaterial({
      map: pipeTexture6,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat7 = new THREE.MeshBasicMaterial({
      map: pipeTexture7,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat8 = new THREE.MeshBasicMaterial({
      map: pipeTexture8,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat9 = new THREE.MeshBasicMaterial({
      map: pipeTexture9,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat10 = new THREE.MeshBasicMaterial({
      map: pipeTexture10,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    let pipeMat11 = new THREE.MeshBasicMaterial({
      map: pipeTexture11,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.5,
    });
    gltfLoader.load(
      "./model/pupm_AB_1024.glb",
      (gltf) => {
        gltf.scene.scale.set(7, 7, 7);
        gltf.scene.name = "city1";
        // console.log(gltf.scene, 888);
        gltf.scene.traverse((child) => {
          if (child.name === "0beng01" || child.name === "0beng02") {
            const animations = child.geometry.morphAttributes.position;
            child.geometry.morphAttributes.position = [];
            child.geometry.morphAttributes.position.push(...animations);
            child.updateMorphTargets();
            gsap.to(params, {
              value: 0,
              duration: 1,
              onUpdate: () => {
                child.morphTargetInfluences[0] = params.value;
              },
            });
          }
          if (child.name === "Water") {
            child.visible = false;
          }
          if (child.name === "Pipe1") {
            child.material = pipeMat1;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe1;
            }, null);
          }
          if (child.name === "Pipe2") {
            child.material = pipeMat2;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe2;
            }, null);
          }
          if (child.name === "Pipe3") {
            child.material = pipeMat3;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe3;
            }, null);
          }
          if (child.name === "Pipe4") {
            child.material = pipeMat4;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe4;
            }, null);
          }
          if (child.name === "Pipe5") {
            child.material = pipeMat5;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe5;
            }, null);
          }
          if (child.name === "Pipe6") {
            child.material = pipeMat6;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe6;
            }, null);
          }
          if (child.name === "Pipe7") {
            child.material = pipeMat7;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe7;
            }, null);
          }
          if (child.name === "Pipe8") {
            child.material = pipeMat8;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe8;
            }, null);
          }
          if (child.name === "dimian001") {
            child.material = depthMat;
            // const water = new WaterBody(
            //   1,
            //   1,
            //   1,
            //   1,
            //   1,
            //   1,
            //   false,
            //   child.geometry
            // );
            // water.mesh.position.set(
            //   child.position.x,
            //   child.position.y,
            //   child.position.z
            // );
            // console.log(child, 123456);
            // child.add(water.mesh);
            child.renderOrder = -1;
          }
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
        let waterBody1 = new WaterBody(
          88,
          116,
          10,
          9,
          -1,
          // 10.5,
          10.1,
          true,
          null,
          "waterBody1"
        );
        let waterBody2 = new WaterBody(
          115,
          77,
          10,
          35.5,
          -1,
          20,
          true,
          null,
          "waterBody2"
        );
        let waterBody3 = new MineWaterBody(
          1.8,
          2.5,
          32,
          -2.5,
          9.5,
          "waterBody3"
        );
        waterBody3.mesh.renderOrder = 100;
        // let controlData = {
        //   waterX: 0,
        //   waterY: 0,
        //   waterZ: 0,
        // };
        // gui.add(controlData, "waterX", -500, 500).onChange((value) => {
        //   waterBody3.mesh.position.x = value;
        // });
        // gui.add(controlData, "waterY", -500, 500).onChange((value) => {
        //   waterBody3.mesh.position.y = value;
        // });
        // gui.add(controlData, "waterZ", -500, 500).onChange((value) => {
        //   waterBody3.mesh.position.z = value;
        // });
        animate(() => {
          waterBody1.water.material.uniforms["time"].value += 1.0 / 60.0; // 每帧更新时间参数
          waterBody2.water.material.uniforms["time"].value += 1.0 / 60.0; // 每帧更新时间参数
        }, null);
        gltf.scene.add(waterBody1.mesh, waterBody2.mesh, waterBody3.mesh);
        waterBody1.mesh.scale.set(1 / 7, 1 / 7, 1 / 7);
        waterBody2.mesh.scale.set(1 / 7, 1 / 7, 1 / 7);
        gltf.scene.visible = false;
        scene.add(gltf.scene);
        eventHub.emit("loaded");
      },
      (xhr) => {
        // console.log(xhr);
        // console.log("加载进度1：", (xhr.loaded / xhr.total) * 100, "%");
        // const percent = xhr.loaded / xhr.total;
        eventHub.emit("loadModel1", xhr.loaded / xhr.total);
      }
    );
    gltfLoader.load(
      "./model/pupm_C_1024.glb",
      (gltf) => {
        gltf.scene.scale.set(7, 7, 7);
        gltf.scene.name = "city2";
        gltf.scene.traverse((child) => {
          if (child.name === "water") {
            child.visible = false;
          }
          if (child.name === "Pipe9") {
            child.material = pipeMat9;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe9;
            }, null);
          }
          if (child.name === "Pipe10") {
            child.material = pipeMat10;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe10;
            }, null);
          }
          if (child.name === "Pipe11") {
            child.material = pipeMat11;
            child.material.needsUpdate = true;
            animate(() => {
              child.material.map.offset.x += 0.003 * this.Pipe11;
            }, null);
          }
          if (child.name === "dimian002") {
            child.material = depthMat;
          }
          if (child.name === "Pool003") {
            child.material = depthMat;
          }
        });
        scene.add(gltf.scene);
        let waterBody = new WaterBody(
          100,
          75,
          10,
          -10,
          -1,
          -3,
          true,
          null,
          "waterBody6"
        );
        let waterBody4 = new MineWaterBody(
          1,
          1.5,
          4.3,
          -0.6,
          -15,
          "waterBody4"
        );
        waterBody4.mesh.renderOrder = 100;
        let waterBody5 = new MineWaterBody(
          5,
          10,
          2.6,
          -0.8,
          -6.2,
          "waterBody5",
          true
        );
        waterBody5.mesh.renderOrder = 100;
        // let controlData = {
        //   waterX: 2.5,
        //   waterY: -1,
        //   waterZ: -6.5,
        // };
        // gui.add(controlData, "waterX", -500, 500).onChange((value) => {
        //   waterBody5.mesh.position.x = value;
        // });
        // gui.add(controlData, "waterY", -500, 500).onChange((value) => {
        //   waterBody5.mesh.position.y = value;
        // });
        // gui.add(controlData, "waterZ", -500, 500).onChange((value) => {
        //   waterBody5.mesh.position.z = value;
        // });
        animate(() => {
          waterBody.water.material.uniforms["time"].value += 1.0 / 60.0; // 每帧更新时间参数
        }, null);
        waterBody.mesh.scale.set(1 / 7, 1 / 7, 1 / 7);
        waterBody5.mesh.scale.set(1 / 7, 1 / 7, 1 / 7);
        gltf.scene.add(waterBody.mesh, waterBody4.mesh, waterBody5.mesh);
        gltf.scene.visible = false;
        eventHub.emit("loaded");
        console.log(gltf.scene, 676767);
      },
      (xhr2) => {
        eventHub.emit("loadModel2", xhr2.loaded / xhr2.total);
      }
    );
  }

  // update(time) {
  //   if (this.mixer) {
  //     // console.log(time);
  //     this.mixer.update(time);
  //   }
  // }

  // createTag(object3d) {
  //   // 创建各个区域的元素
  //   const element = document.createElement("div");
  //   element.className = "elementTag";
  //   element.innerHTML = `
  //     <div class="elementContent">
  //       <h3>${object3d.name}</h3>
  //       <p>温度：26℃</p>
  //       <p>湿度：50%</p>
  //     </div>
  //   `;

  //   const objectCSS3D = new CSS3DObject(element);
  //   objectCSS3D.position.copy(object3d.position);
  //   objectCSS3D.scale.set(0.2, 0.2, 0.2);
  //   return objectCSS3D;
  //   // scene.add(objectCSS3D);
  // }

  // showFloor1() {
  //   this.floor1Group.visible = true;
  // }
  // showFloor2() {
  //   this.floor2Group.visible = true;
  //   this.fighterGroup.visible = true;
  //   this.floor2Tags.forEach((tag) => {
  //     tag.visible = true;
  //   });
  // }

  // hideFloor1() {
  //   this.floor1Group.visible = false;
  // }
  // hideFloor2() {
  //   this.floor2Group.visible = false;
  //   this.fighterGroup.visible = false;
  //   this.floor2Tags.forEach((tag) => {
  //     tag.visible = false;
  //   });
  // }

  // hideWall() {
  //   this.wallGroup.visible = false;
  // }
  // showWall() {
  //   this.wallGroup.visible = true;
  // }
  // showFighter() {
  //   this.floor1Group && (this.floor1Group.visible = false);
  //   this.floor2Group && (this.floor2Group.visible = false);
  //   this.wallGroup && (this.wallGroup.visible = false);
  //   this.fighterGroup.visible = true;
  // }
  // createPoints(object3d) {
  //   if (!this.fighterPointsGroup) {
  //     this.fighterPointsGroup = this.transformPoints(object3d);
  //     this.scene.add(this.fighterPointsGroup);
  //   }
  // }

  // transformPoints(object3d) {
  //   // 创建纹理图像
  //   const texture = new THREE.TextureLoader().load("./assets/particles/1.png");
  //   const group = new THREE.Group();

  //   function createPoints(object3d, newObject3d) {
  //     if (object3d.children.length > 0) {
  //       object3d.children.forEach((child) => {
  //         if (child.isMesh) {
  //           // 随机生成颜色
  //           const color = new THREE.Color(
  //             Math.random(),
  //             Math.random(),
  //             Math.random()
  //           );
  //           // const material = new THREE.PointsMaterial({
  //           //   size: 0.1,
  //           //   color: color,
  //           //   map: texture,
  //           //   blending: THREE.AdditiveBlending,
  //           //   transparent: true,
  //           //   depthTest: false,
  //           // });

  //           const material = new THREE.ShaderMaterial({
  //             uniforms: {
  //               uColor: { value: color },
  //               uTexture: { value: texture },
  //               uTime: {
  //                 value: 0,
  //               },
  //             },
  //             vertexShader: vertexShader,
  //             fragmentShader: fragmentShader,
  //             blending: THREE.AdditiveBlending,
  //             transparent: true,
  //             depthTest: false,
  //           });
  //           const points = new THREE.Points(child.geometry, material);
  //           points.position.copy(child.position);
  //           points.rotation.copy(child.rotation);
  //           points.scale.copy(child.scale);
  //           newObject3d.add(points);
  //           createPoints(child, points);
  //         }
  //       });
  //     }
  //   }

  //   createPoints(object3d, group);
  //   // object3d.traverse((child) => {
  //   //   if (child.isMesh) {
  //   //     const points = child.geometry.attributes.position.array;
  //   //     const geometry = new THREE.BufferGeometry();
  //   //     geometry.setAttribute(
  //   //       "position",
  //   //       new THREE.Float32BufferAttribute(points, 3)
  //   //     );

  //   //     // 随机生成颜色
  //   //     const color = new THREE.Color(
  //   //       Math.random(),
  //   //       Math.random(),
  //   //       Math.random()
  //   //     );
  //   //     const material = new THREE.PointsMaterial({
  //   //       size: 0.1,
  //   //       color: color,
  //   //     });
  //   //     const pointsMesh = new THREE.Points(geometry, material);
  //   //     pointsMesh.position.copy(child.position);
  //   //     pointsMesh.rotation.copy(child.rotation);
  //   //     pointsMesh.scale.copy(child.scale);
  //   //     group.add(pointsMesh);
  //   //   }
  //   // });
  //   return group;
  // }

  // pointsBlast() {
  //   this.fighterPointsGroup.traverse((child) => {
  //     if (child.isPoints) {
  //       let randomPositionArray = new Float32Array(
  //         child.geometry.attributes.position.count * 3
  //       );
  //       for (let i = 0; i < child.geometry.attributes.position.count; i++) {
  //         randomPositionArray[i * 3 + 0] = (Math.random() * 2 - 1) * 10;
  //         randomPositionArray[i * 3 + 1] = (Math.random() * 2 - 1) * 10;
  //         randomPositionArray[i * 3 + 2] = (Math.random() * 2 - 1) * 10;
  //       }

  //       child.geometry.setAttribute(
  //         "aPosition",
  //         new THREE.BufferAttribute(randomPositionArray, 3)
  //       );

  //       gsap.to(child.material.uniforms.uTime, {
  //         value: 10,
  //         duration: 10,
  //       });
  //     }
  //   });
  // }

  // pointsBack() {
  //   this.fighterPointsGroup.traverse((child) => {
  //     if (child.isPoints) {
  //       gsap.to(child.material.uniforms.uTime, {
  //         value: 0,
  //         duration: 10,
  //       });
  //     }
  //   });
  // }
}
