import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
// import * as dat from "dat.gui";
//创建gui对象
// const gui = new dat.GUI();
// 初始化场景
const scene = new THREE.Scene();
// 添加雾霾
// const fog = new THREE.Fog(0x000000, 0, 10);
// scene.fog = fog;

// const cubeTextureLoader = new THREE.CubeTextureLoader().setPath("./textures/");
// const texture = cubeTextureLoader.load([
//   "1.jpg",
//   "2.jpg",
//   "3.jpg",
//   "4.jpg",
//   "5.jpg",
//   "6.jpg",
// ]);
// 添加圆柱形天空
const rgbeloader = new RGBELoader();

rgbeloader.loadAsync("./textures/2kkkk.hdr").then((texture) => {
  // 设置纹理为圆柱形纹理
  texture.mapping = THREE.EquirectangularReflectionMapping;
  // 添加天空环境
  scene.background = texture;
  scene.environment = texture;
  scene.backgroundIntensity = 1.5;
});
// const bgLoader = new THREE.TextureLoader();

// bgLoader.loadAsync("./textures/background.jpg").then((texture) => {
//   // 设置纹理为圆柱形纹理
//   // texture.mapping = THREE.EquirectangularReflectionMapping;
//   // 添加天空环境
//   scene.background = texture;
//   scene.environment = texture;
// });
// 场景亮度物理灯光效果
// 1设置色调映射
// 2设置曝光
// 3设置场景灯光
// scene.background = new THREE.Color(0x354253);
// let controlData = {
//   lightColor: 0xffffff,
//   intensity: 1,
// };
// 给场景添加平行光
const light = new THREE.DirectionalLight(0xb67200, 0.5);
light.position.set(1000, 1000, 1000);
light.castShadow = true;
// gui.addColor(light, "color");
// gui.addColor(controlData, "lightColor").onChange((value) => {
//   light.color.set(value);
// });
// gui.add(controlData, "intensity").onChange((value) => {
//   light.intensity = value;
// });
// light.shadow.mapSize.width = 1024;
// light.shadow.mapSize.height = 1024;
// const lightHelper = new THREE.DirectionalLightHelper(light, 5);
// scene.add(lightHelper);
scene.add(light);

// // 给场景添加环境光
// const ambient = new THREE.AmbientLight(0xffffff, 0.5);
// scene.add(ambient);
export default scene;
